<template>
  <b-card
    title="角色管理"
  >
    <div class="custom-search">

      <!-- advance search input -->
      <b-row class="mb-2">
        <b-col md="3">
          <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label-size="lg"
            label="角色名称"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="searchRoleName"
              placeholder="请输入角色名称"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label-size="lg"
            label="角色备注"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="searchRoleNote"
              placeholder="请输入角色备注"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" offset="3" class="tRight">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="pageRole(true)"
          >
            搜索
          </b-button>
        </b-col>
      </b-row>
      <b-button
        class="mb-2 mr-2"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        :to="{ name: 'auth-roleInfo'}"
        v-if="$checkButtonPermission('202002001')"
      >
        添加
      </b-button>
    </div>

    <!-- table -->
    <vue-good-table
      ref="adminTable"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :sort-options="{
        enabled: false,
      }"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageSize
      }"
      theme="my-theme"
      @on-cell-click="onCellClick"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'enabled'">
          <span>{{props.row.enabled ? '启用' : '禁用'}}</span>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- 编辑 -->
            <b-dropdown-item>
              <span>
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">编辑</span>
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="tCenter">
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="pageSize"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
               @change="currentPageHandleChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol,BCard,BButton,BModal,BDropdown,BDropdownItem,BFormTextarea,BFormRadio
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import vSelect from 'vue-select'
import request from '@/api/request'
export default {
  components: {
    BCard,VueGoodTable,BAvatar,BPagination,BFormGroup,BFormInput,BFormSelect,BRow,BCol,BButton,
    vSelect,BModal,BDropdown,BDropdownItem,BFormTextarea,BFormRadio,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordState: null,
      currentPage: 1,
      pageSize: 10,
      searchRoleName: '',
      searchRoleNote: '',
      dir: false,
      roleOptions: [
      ],
      roleFilter:null,
      columns: [
        {
          label: '角色名称',
          field: 'name',
        },
        {
          label: '角色备注',
          field: 'note',
        },
        {
          label: '创建者',
          field: 'createUName',
        },
        {
          label: '创建时间',
          field: 'createTime',
        },
        {
          label: '操作',
          field: 'action',
        },
      ],
      rows: [],
      total: 0,
      searchTerm: '',
      updatePassword: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.pageRole()
  },
  methods: {
    currentPageHandleChange(currentPage){
      this.currentPage = currentPage
      this.pageRole()
    },
    onRowClick(params) {
      console.log(params.row)
    },
    onSelectAll(params){
      console.log('all')
    },
    onRowDoubleClick(params) {
      console.log(params)
    },
    onCellClick(params) {
      if(params.column.field == 'action'){
        console.log(params.row)
        this.$router.push({name:'auth-roleInfo',query:{id: params.row.id}});
      }
    },
    pageRole(resetCurrentPage){
      if(resetCurrentPage)
        this.currentPage = 1
      request.get('tob/user/bRole/page', {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        roleName: this.searchRoleName,
        roleNote: this.searchRoleNote
      }).then(res => {
        if (res.data.success) {
          this.rows = res.data.data.data
          this.total = res.data.data.count
          console.log('rows',this.rows)
          console.log('total',this.total)
        }
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
